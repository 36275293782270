import { GridColDef } from "@material-ui/data-grid";
import { ReactComponent as EditIcon } from "assets/icon/edit.svg";
import { ReactComponent as RedTrashIcon } from "assets/icon/red_trash.svg";
import { ReactComponent as CopyIcon } from "assets/icon/copy.svg";
import {
  Box,
  Button,
  Divider,
  Modal,
  Notification,
  Table,
  Typography,
} from "components";
import { MaterialIcon } from "components/common/MaterialIcon";
import { EnumMasterInsuranceState } from "constants/enums/master-insurance-state";
import { PERMISSIONS } from "constants/enums/permissions";
import { EnumRowState } from "constants/enums/row-state";
import { compose, withHooks, withStores, withTranslation } from "enhancers";
import { TFunction } from "i18next";
import insuranceStore from "stores/insuranceStore";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { gql, paths, toCurrency } from "utils/helper";

const Container = styled("div")`
  padding: 0px 0px 0px 32px;
  position: relative;
`;

const AddButton = styled(Button)`
  position: absolute;
  top: 0px;
  right: 16px;
`;

interface InsuranceDetailProps {
  label: string;
  handleClickAddInsurance: () => void;
  t: TFunction;
  tableData: [];
  columns: GridColDef[];
  viewOnly: boolean;
}

const InsuranceListComponent = (props: InsuranceDetailProps) => (
  <Container>
    <Box py="8px">
      <Typography variant="body2">{props.label}</Typography>
    </Box>
    <Divider />
    <Table
      columns={props.columns}
      rows={props.tableData}
      density="compact"
      autoHeight
      rowsPerPageOptions={[5, 10]}
      style={{ minHeight: "40px" }}
      hideFooter
      hideFooterPagination
      hideFooterRowCount
      disableSelectionOnClick
      noRowText="client.components.Table.noInsuranceData"
    />

    {props.tableData.length === 0 && <Divider />}
    <Box mb={6}></Box>
  </Container>
);

const API = {
  CHECK_HAS_BEEN_USED: gql`
    mutation CHECK_HAS_BEEN_USED($id: String!) {
      checkInsurance(id: $id) {
        hasBeenUsed
        hasBeenGroup
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => {
    return {
      currentUser: stores.appStore.currentUser,
      insurances: stores.insuranceStore.insurances,
      insuranceSubState: stores.insuranceStore.insuranceSubState,
    };
  }),
  withTranslation({ prefix: "pages.main.insurance.InsuranceList" }),
  withHooks((props: any, hooks: any) => {
    const {
      useMemo,
      useCallback,
      useParams,
      useMutation,
      useDataTranslation,
    } = hooks;
    const {
      label,
      id: insuranceTypeId,
      symbol,
      insurances,
      currentUser,
      t,
      disablePreventLeaveDirtyForm,
      viewOnly,
      isFamily,
      year,
    } = props;

    const { id } = useParams();

    const [checkHasBeenUsed] = useMutation(API.CHECK_HAS_BEEN_USED);

    const hasEditPermission = useMemo(
      () =>
        currentUser?.role?.permissions.includes(
          PERMISSIONS.INSURANCE_MANAGEMENT_EDIT
        ),
      [currentUser]
    );

    const handleDelete = useCallback(
      async (id: string) => {
        const { data } = await checkHasBeenUsed({
          variables: { id },
        });
        const hasBeenUsed = data?.checkInsurance?.hasBeenUsed;
        const hasBeenGroup = data?.checkInsurance?.hasBeenGroup;
        if (hasBeenUsed) {
          Notification.error(t(".cannotDeleteWhenUsedInPackage"));
        } else if (hasBeenGroup) {
          Notification.error(t(".cannotDeleteWhenHasBeenGroup", { year }));
        } else {
          insuranceStore.deleteInsurance(id);
          Notification.notify(t(".deleteSuccess"));
        }
      },
      [checkHasBeenUsed, t, year]
    );

    const handleClickDeleteInsurance = useCallback(
      (props: any) => {
        const name = props.row.name;
        Modal.open({
          title: t(".deleteModalTitle"),
          children: (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
                {t(".deleteModalInfo1")} &nbsp;
              </Typography>
              <Typography variant="body2" color={AppColor["Text/Black"]}>
                {name} &nbsp;
              </Typography>
              <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
                {t(".deleteModalInfo2")}
              </Typography>
            </div>
          ),
          cancelButtonLabel: t(".close"),
          okButtonLabel: t(".confirmDelete"),
          okButtonVariant: "outlined",
          onOk: async ({ close }: any) => {
            await handleDelete(props.id);
            close();
          },
        });
      },
      [t, handleDelete]
    );

    const handleClickEditInsurance = useCallback(
      async (props: any) => {
        await disablePreventLeaveDirtyForm();
        paths.editInsurancePath(id, props.id).push();
      },
      [id, disablePreventLeaveDirtyForm]
    );

    const insurancesFiltered = useMemo(() => {
      if (insurances) {
        const list = Object.keys(insurances).map((key) => insurances[key]);

        return list.filter(
          (insurance) =>
            insurance.rowState !== EnumRowState.DELETED &&
            insurance.insuranceTypeId === insuranceTypeId
          // insurance.isFamily === isFamily
        );
      }
      return [];
    }, [insurances, insuranceTypeId]);

    const insurancesFilteredNewTranslation = useDataTranslation(
      insurancesFiltered
    );

    const handleDuplicate = useCallback(
      async (insuranceId: any) => {
        await disablePreventLeaveDirtyForm();
        paths.duplicateInsurancePath(id, insuranceId).push();
      },
      [id, disablePreventLeaveDirtyForm]
    );

    const handleClickDuplicateInsurance = useCallback(
      (props: any) => {
        const name = props.row.name;
        Modal.open({
          title: t(".duplicateModalTitle"),
          children: (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
                {t(".duplicateModalContent")} &nbsp;
              </Typography>
              <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
                {`“${name}”`} &nbsp;
              </Typography>
            </div>
          ),
          cancelButtonLabel: t(".close"),
          okButtonLabel: t(".duplicateModalTitle"),
          okButtonVariant: "outlined",
          onOk: async ({ close }: any) => {
            await handleDuplicate(props.id);
            close();
          },
        });
      },
      [t, handleDuplicate]
    );

    const tableData = useMemo(() => {
      return (
        insurancesFilteredNewTranslation.map((data: any) => ({
          ...data,
          premium: toCurrency(data.premium, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }),
          actions: viewOnly
            ? []
            : [
                // {
                //   Icon: CopyIcon,
                //   onClick: handleClickDuplicateInsurance,
                // },
                {
                  Icon: EditIcon,
                  onClick: handleClickEditInsurance,
                },
                {
                  Icon: RedTrashIcon,
                  onClick: handleClickDeleteInsurance,
                },
              ],
        })) || []
      );
    }, [
      insurancesFilteredNewTranslation,
      viewOnly,
      handleClickEditInsurance,
      handleClickDeleteInsurance,
    ]);

    const columns: GridColDef[] = useMemo(
      (): GridColDef[] => [
        {
          width: 190,
          field: "name",
          headerName: t(".name") || "",
        },
        {
          width: 100,
          field: "premium",
          headerName: t(".premium") || "",
          type: "textEnd",
        },
        // {
        //   width: 60,
        //   field: "coverageLevel",
        //   headerName: t(".coverageLevel") || "",
        //   type: "textEnd",
        // },
        {
          width: 230,
          field: "remark",
          headerName: t(".remark") || "",
        },
        {
          width: 130,
          field: "actions",
          headerName: " ",
          filterable: false,
          sortable: false,
          type: "actions",
        },
      ],
      [t]
    );

    const handleClickAddInsurance = useCallback(async () => {
      await disablePreventLeaveDirtyForm();
      paths.addInsurancePath(id, { insuranceType: symbol }).push();
    }, [id, symbol, disablePreventLeaveDirtyForm]);

    return {
      label,
      handleClickAddInsurance,
      tableData,
      columns,
      viewOnly,
    };
  })
);

export const InsuranceList = enhancer(InsuranceListComponent);
